import React from "react"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
// import { OutboundLink } from "gatsby-plugin-google-analytics"
import styled from "@emotion/styled"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import { device } from "../helpers/devices"
import { colors } from "../helpers/colors"

import SadNoodleImage from "../images/sad-noodle.png"
import SpotifyImage from "../images/spotify-button.png"
import LogoImage from "../images/kd-logo.png"

const H1Styled = styled.h1`
  font-family: "Montserrat";
  font-display: swap;
  font-weight: 700;
  color: ${colors.kdyellow};
  text-align: center;
  margin-top: 60px;
  margin-bottom: 0px;
  font-size: 2.3em;
  @media only screen and ${device.mobileS} {
    font-size: 2.3em;
  }
  @media only screen and ${device.mobileM} {
    font-size: 2.7em;
  }
  @media only screen and ${device.mobileL} {
    font-size: 3em;
  }
  @media only screen and ${device.tablet} {
    font-size: 4em;
  }
`

const SadNoodleStyled = styled.img`
  width: 100%;
  height: 100%;
  max-width: 200px;
  margin: 40px 0 50px;

  @media only screen and ${device.mobileS} {
    max-width: 200px;
    margin: 40px 0 50px;
  }
  @media only screen and ${device.tablet} {
    max-width: 300px;
    margin: 40px 0 80px;
  }
`

const Paragraph = styled.p`
  font-family: "Montserrat";
  font-display: swap;
  font-weight: 500;
  color: #ffffff;

  font-size: 0.81em;
  @media only screen and ${device.mobileS} {
    text-align: left;
    text-align-last: left;
  }
  @media only screen and ${device.tablet} {
    text-align: justify;
    text-align-last: center;
  }
`

const ParagraphCol = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: 530px;
  justify-content: center;
  margin-left: 50px;
  margin-right 50px;
`

const SpotifyLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  margin: 0 auto;
  margin-top: 40px;
  background-color: ${colors.kdyellow};
  border-radius: 5px;
  &:hover {
    background-color: ${colors.kdyellowHover};
  }
`

const SpotifyImageStyled = styled.img`
  display: block;
  width: 100%;
  height: auto;
  padding: 0 1.2em;
  margin: 0;
`

const KDLogoImage = styled.img`
  margin: 40px 0;
  width: 100%;
  max-width: 100px;
`

const EngFrLink = styled(Link)`
  font-family: "Montserrat";
  font-display: swap;
  font-weight: 500;
  font-size: 0.5em;
  color: rgba(255, 255, 255, 0.5);
  padding-right: 20px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.8);
  }
`

const pageTitle = "We're sorry."
const tabTitle = "KD Says Sorry"

const metaTitle = "Read Our Apology"
const metaDesc =
  "Canada, you’ve been saying some not so awesome stuff about the taste of Kraft Dinner lately. So we want to apologize."
const metaSiteName = "kdsayssorry.ca"
const metaURL = "https://kdsayssorry.ca"
// TODO: Change the image url once live!
const imageURL =
  "https://kraftsayssorry.rethinkcanada.ca/images/social_card_800x418_Eng.jpg"

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>{tabTitle}</title>
        <meta name="" content="" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="og:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDesc} />
        <meta name="twitter:description" content={metaDesc} />
        <meta name="twitter:image" content={imageURL} />
        <meta name="og:image" content={imageURL} />
        <meta name="twitter:site" content={metaSiteName} />
        <meta property="og:site_name" content={metaSiteName} />
        <meta name="twitter:url" content={metaURL} />
        <meta name="og:url" content={metaURL} />
      </Helmet>
      <Row>
        <Col className="text-right" style={{ paddingTop: 20 }}>
          <EngFrLink to="/fr">EN / FR</EngFrLink>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 12 }}>
          <H1Styled>{pageTitle}</H1Styled>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <SadNoodleStyled src={SadNoodleImage} />
      </Row>
      <Row style={{ justifyContent: "center" }}>
        <ParagraphCol>
          <Paragraph>
            Hey Canada, we’ve been reading the Internet lately and we've noticed
            some not so awesome stuff about the taste of Kraft Dinner. Stuff
            like, “KD doesn't taste right” and that it "tastes bad." Bad?! No.
            This simply cannot be. As devoted stewards of a national treasure,
            it is our duty to protect this cherished dish, and we have fallen
            short. After all, KD is Canada’s official, unofficial national food.
            It's as Canadian as apologizing. So that’s exactly what we’re going
            to do. We're sorry. But fret not. We’re making this right.
          </Paragraph>
          <Paragraph>
            We’re serving up a big ol’ bowl of good news. We’ve reached out to
            Canadians just like you, to taste-test some changes to KD to get it
            perfect. We've enhanced noodle integrity to its highest level and
            added more cheese. That's right. More cheese in every box of KD. So
            that from now on, KD is going to taste like it should.
          </Paragraph>
          <Paragraph>
            We aren’t just fixing KD; we also want you to know how truly sorry
            we are. So we’re going to offer you our deepest apologies, the way
            kindred spirits have healed broken bonds for generations: in radio’s
            all-request hour. We want to apologize by dedicating songs to
            Canadians on radio stations across the country. We’re even
            dedicating songs to you right now, in this very letter, just to
            prove how deeply sorry we are.
          </Paragraph>
          <Paragraph>
            Rest assured Canada. We're on it. It's gotta be right. It's too
            important not to be. Because after all, it's gotta be KD.
          </Paragraph>
          <Paragraph style={{ marginBottom: 0 }}>Your friends at KD.</Paragraph>
        </ParagraphCol>
      </Row>
      <Row>
        <Col className="text-center" xl={{ span: 12 }}>
          <SpotifyLink
            href="https://open.spotify.com/playlist/7G5BLFOd4mYu9m1zwHBTmq"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SpotifyImageStyled src={SpotifyImage} />
          </SpotifyLink>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 12 }} className="text-center">
          <KDLogoImage src={LogoImage} />
        </Col>
        {/* <Col
          xs={{ span: 2 }}
          style={{
            display: "flex",
          }}
        >
          <ScrollTopButton />
        </Col> */}
      </Row>
    </Layout>
  )
}

export default IndexPage
